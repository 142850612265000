<template>
    <div class="user-wrapper">
        <div class="container">
            <div class="user-main">
                <div class="user-info" v-if="$store.state.login">
                    <div v-if="$store.state.userInfo.profile_photo_url">
                        <img :src="$store.state.userInfo.profile_photo_url"
                            style="width:80px;height:80px;border-radius: 80px;" />
                    </div>
                    <div v-else>
                        <img src="@/assets/images/web/user_info/user_girl.png" v-if="$store.state.userInfo.sex==2"
                            style="width:80px;height:80px;border-radius: 80px;" />
                        <img src="@/assets/images/web/user_info/user_boy.png"
                            v-if="$store.state.userInfo.sex==1||$store.state.userInfo.sex==0"
                            style="width:80px;height:80px;border-radius: 80px;" />
                    </div>
                    <div>
                        <span>{{$store.state.userInfo.name}} </span>
                    </div>
                    <div v-if="vip>0">
                        <span style="height:16px;" ><img src="@/assets/images/web/member/会员.png"/></span>
                        <span  v-if="vip==1">企业会员</span>
                        <span  v-if="vip==2">个人会员</span>
                    </div>
                    <div v-if="vip>0">到期时间：{{coop}}</div>
                    <!-- <div v-if="$store.state.userInfo.vip_auth!=0&&$store.state.userInfo.vip_get!=0">
                        <img src="@/assets/images/web/vip-logo.svg" style="width:23px;height:17px;" />
                    </div> -->
                </div>
                <div class="menu-info">
                    <p :selected="menukey==1" @click="onChangeTab(1)">个人资料</p>
                    <p :selected="menukey==2" @click="onChangeTab(2)">我的收藏</p>
                    <p :selected="menukey==3" @click="onChangeTab(3)">我的会员</p>
                    <p :selected="menukey==11" @click="onChangeTab(11)">我的套餐</p>
                    <p :selected="menukey==4" @click="onChangeTab(4)">我的订单</p>
                    <p :selected="menukey==5" @click="onChangeTab(5)">发票管理</p>
                    <!-- <p :selected="menukey==6" @click="onChangeTab(6)">授权认证</p> -->
                    <p :selected="menukey==7" @click="onChangeTab(7)">授权管理</p>
                    <p :selected="menukey==8" @click="onChangeTab(8)">优惠券</p>
                    <p :selected="menukey==9" @click="onChangeTab(9)">账号安全</p>
                </div>
            </div>
            <div :class="[showWhite?'cont-main2': 'cont-main']" id="boxTable">
                <component :is="curComponent"></component>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapMutations } from "vuex";
    export default {
        data() {
            return {
                curComponent: '',
                ComponentList: {
                    'member1': './user/user.vue',
                    'member2': './favorite/favorite.vue',
                    'member3': './vip/vip_v2.vue',
                    'member4': './order_v2/order.vue',
                    'member5': './invoice/invoice.vue',
                    'member6': './auth/auth.vue',
                    'member7': './authlog/authlog.vue',
                    'member8': './coupon/coupon.vue',
                    'member9': './user/safe.vue',
                    'member11': './package/package.vue',
                },
                coop:'',
                vip:0,
                showWhite:false,
            }
        },
        created() {
            this.setDefaultIndex();
            this.isLogin();
        },
        computed: {
            userInfo_() {
                var ret = this.$store.state.userInfo;
                this.process(ret);
                return Object.assign({}, this.$store.state.userInfo);
            },
        },
        watch: {
            $route() {
                this.setDefaultIndex();
            },
            userInfo_(newVal, oldVal) {
                this.process(newVal);
            }
        },
        methods: {
            ...mapMutations(["changeLoginAndRegisterDialog"]),
            process(userdata){
                if (userdata['company_vip_coop'] != null && userdata['company_vip_coop'] > 0) {
                    var date = new Date(parseInt(userdata['company_vip_coop'])*1000);
                    var y = date.getFullYear();
                    var m = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
                    var d = date.getDate() < 10 ? '0'+ date.getDate() : date.getDate();
                    this.coop = y+"-"+m+"-"+d;
                    this.vip = 1;
                }
                else if (userdata['person_vip_coop'] != null && userdata['person_vip_coop'] > 0) {
                    var date2 = new Date(parseInt(userdata['person_vip_coop'])*1000);
                    
                    var y2 = date2.getFullYear();
                    var m2 = (date2.getMonth() + 1 < 10 ? '0' + (date2.getMonth() + 1) : date2.getMonth() + 1);
                    var d2 = date2.getDate() < 10 ? '0'+ date2.getDate() : date2.getDate();
                    this.coop = y2+"-"+m2+"-"+d2;
                    this.vip = 2;
                }
            },
            isLogin() {
                if (!this.$store.state.login) {
                    this.changeLoginAndRegisterDialog(true);
                }
            },
            onChangeTab(newVal) {
                this.$root.$goSearch({
                    menukey: newVal
                })
            },
            setDefaultIndex() {
                let menukey = this.$route.query.menukey || '1';
                this.menukey = menukey;
                let p = this.ComponentList['member' + this.menukey];
                this.curComponent = () => import(`${p}`) // 动态组件引入
                if(menukey==3||menukey==4||menukey==11){
                    this.showWhite = false;
                }else{
                    this.showWhite = true;
                }
            },
        }
    }
</script>
<style>
</style>
<style lang="scss" scoped>
    ::v-deep {
        .m-bottom-lg {
            margin-bottom: -25px !important;
        }
    }

    .container {
        width:80%;
    }

    .user-wrapper {
        width: 100%;
        min-height: 300px;
        padding-top: 24px;
        background: #F9FAFA;
        vertical-align: top;

        .user-main {
            display: inline-block;
            width: 18%;

            //position: fixed;
            //top: 88px;
            //z-index: 4;
            .user-info,
            .menu-info {
                background: #FFFFFF;
            }

            .user-info {
                height: 182px;
                width: 100%;
                margin: 0 auto;
                text-align: center;

                div:nth-child(1) {
                    width: 100%;
                    height: 99px;
                    padding-top: 12px;
                    text-align: center;
                }
                div:nth-child(2) {
                    /* padding-top: 12px; */
                }
                div:nth-child(3) {
                    /* width: 40%; */
                    margin: 0 auto;
                    margin-top: 4px;
                    /* margin-top: -40px; */
                    text-align: right;
                    /* margin-right: 38%; */
                    width: 90px;
                    height: 24px;
                    background: #FFB039;
                    border-radius: 4px;
                    font-size: 12px;
                    color:white;
                    line-height: 24px;
                    padding-right: 12px;
                }
                div:nth-child(4) {
                    width: 100%;
                    margin: 0 auto;
                    color:#333333;
                    font-size: 12px;
                    margin-top: 4px;
                    /* margin-top: -40px; */
                    /* text-align: right;
                    margin-right: 38%; */
                }
                
            }

            .menu-info {
                height: 600px;
                width: 100%;
                margin: 0 auto;
                margin-top: 16px;
                padding-top: 19px;
                text-align: center;

                p {
                    display: block;
                    height: 22px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #333333;
                    line-height: 22px;
                    padding-bottom: 39px;
                }

                p[selected],
                p:hover {
                    color: #FF7B13;
                }

                p:hover {
                    cursor: pointer;
                    color: #FF7B13;
                }
            }
        }

        .cont-main {
            display: inline-block;
            vertical-align: top;
            width: 81%;
            margin-left: 1%;
            background: #F9FAFA;
            min-height: 777px;
            padding-bottom: 5%;
        }
        .cont-main2 {
            display: inline-block;
            vertical-align: top;
            width: 81%;
            margin-left: 1%;
            background: #ffffff;
            min-height: 777px;
            padding-bottom: 5%;
        }
    }

    @media only screen and (max-width: $screen-width-md) {
        .user-wrapper {
            .user-main {
                .menu-info {
                    p {
                        font-size: 12px;
                    }
                }
            }
        }
    }
</style>